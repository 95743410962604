<template>
  <div class="home">
    <Header 
      title="Léo Blondin" 
      work="Old PF" 
      about="About" 
    />
    <Heroscreen />
    <Project />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Heroscreen from '@/components/Heroscreen.vue'
import Project from '@/components/Project.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Heroscreen,
    Project,
    Footer
  }
}
</script>
<style lang="scss">
  html {
    scroll-behavior: smooth;
  }
  body {
    overflow-x: hidden;
  }
</style>