<template>
  <header class="header">
    <h1 class="header_name">
      <router-link to="/">
        {{ title }}
      </router-link>
    </h1>
    <div class="header_nav">
      <a href="http://leoblondin.com/old/" target="_blank">{{ work }}
        <div class="header_circle">
          <svg xmlns="http://www.w3.org/2000/svg" width="1531.632" height="713.728" viewBox="0 0 1531.632 713.728">
            <g id="Ellipse_home" data-name="Ellipse home" transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)" fill="none" stroke="#fff" stroke-width="30" opacity="0.7">
              <ellipse cx="740.5" cy="281" rx="740.5" ry="281" stroke="none"/>
              <ellipse cx="740.5" cy="281" rx="740" ry="280.5" fill="none"/>
            </g>
          </svg>
        </div>
      </a> 
      <router-link to="/about">
        {{ about }}
        <div class="header_circle">
          <svg xmlns="http://www.w3.org/2000/svg" width="1531.632" height="713.728" viewBox="0 0 1531.632 713.728">
            <g id="Ellipse_home" data-name="Ellipse home" transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)" fill="none" stroke="#fff" stroke-width="30" opacity="0.7">
              <ellipse cx="740.5" cy="281" rx="740.5" ry="281" stroke="none"/>
              <ellipse cx="740.5" cy="281" rx="740" ry="280.5" fill="none"/>
            </g>
          </svg>
        </div>
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: String,
    work: String,
    about: String,
  },
};
</script>

<style lang="scss">
    .header {

        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-flow: row nowrap;
        align-content: center;
        justify-content: space-between;
        width: 90%;
        margin: 32px auto;
        z-index: 500;

        &_name{

            font-family: "Telegraf-UltraLight";
            font-size: 1em;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: #FFF;
            margin: 0;

            a {
              text-decoration: none;
              color: #FFF;
            }

        }

        &_nav {

            display: flex;
            flex-flow: row nowrap;
            align-content: center;
            justify-content: space-between;
            width: 150px;
           
            a {
                position: relative;
                box-sizing: border-box;
                font-family: "Telegraf-UltraLight";
                font-size: 1em;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: #FFF;
                text-decoration: none;
                margin: 0;

                &:hover {
                  .header_circle {
                    svg {
                      stroke-dashoffset: 0;
                    }
                  }
                }

              .header_circle {

                position: absolute;
                top: 0;
                left: 0;
                width: 200%;
                height: 200%;
                transform: translate(-25px, -10px);

                svg {
                  width: 100%;
                  height: 100%;
                  stroke-dasharray: 3500;
                  stroke-dashoffset: 3500;
                  transform: rotate(-10deg);
                  transition: 0.5s ease-out;
                }
              }
            }

        }
    }
</style>
