<template>
  <div class="heroscreen">
    <div class="heroscreen_content">
      <div class="heroscreen_title bigTitle">
          <div class="hidding_name">
              <div class="bigTitle_name" ref="name">Léo Blondin</div>
          </div>
          <div class="hidding_desc">
              <div class="bigTitle_desc" ref="desc">Big nerd</div>
          </div>
          <div class="hidding_smallDesc">
              <div class="bigTitle_smallDesc">
                  <span ref="parLeft">(</span>
                  <div class="bigTitle_smallDesc_info" ref="smallDesc">
                      I Don't have sex with computers CALM DOWN
                  </div>
                  <span ref="parRight">)</span>
              </div>
          </div>
          <div class="hidding_job">
              <div class="bigTitle_job" ref="job">And Developer</div>
          </div>
      </div>
      <div class="circle" >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1531.632"
              height="713.728"
              viewBox="0 0 1531.632 713.728"
              ref="circle"
          >
              <g
                  id="Ellipse_home"
                  data-name="Ellipse home"
                  transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="1"
                  opacity="0.7"
              >
              <ellipse cx="740.5" cy="281" rx="740.5" ry="281" stroke="none" />
              <ellipse cx="740.5" cy="281" rx="740" ry="280.5" fill="none" />
              </g>
          </svg>
      </div>
      <CanvasParticle/>
    </div>
  </div>
</template>

<script>
    import { TimelineLite, Power3, Power4, gsap } from 'gsap'
    import SplitText from '../script/Split'

    import CanvasParticle from './CanvasParticle'

    export default {
      name: "Heroscreen",
      components: {
        CanvasParticle
      },

      mounted() {

          //Declaration for GSAP
          const { parLeft, parRight, smallDesc, circle } = this.$refs
          const timeline = new gsap.timeline()

          const name_split = new SplitText('.bigTitle_name', { type: 'chars, words' })
          const desc_split = new SplitText('.bigTitle_desc', { type: 'chars, words' })
          const job_split = new SplitText('.bigTitle_job', { type: 'chars, words' })
          const smallDesc_split = new SplitText('.bigTitle_smallDesc_info', { type: 'chars, words' })
          const char_name = name_split.chars
          const char_desc = desc_split.chars
          const char_job = job_split.chars
          const char_smallDesc = smallDesc_split.words

          // Anime for GSAP
          timeline.fromTo(char_name, { duration: 0.5, opacity:0, y:"150%"}, { duration: 1.5, opacity:1,  y:0, stagger: 0.07, ease: Power4.easeOut},'=0')
          timeline.fromTo(char_desc, { duration: 0.5, opacity:0, y:"150%"}, { duration: 1.5, opacity:1,  y:0, stagger: 0.07, ease: Power4.easeOut},'-=1.7')
          timeline.fromTo(char_job, { duration: 0.5, opacity:0, y:"150%"}, { duration: 1.5, opacity:1,  y:0, stagger: 0.07, ease: Power4.easeOut},'-=1.4')
          timeline.to(parLeft, 1, {opacity: 1, ease: Power3.easeOut}, '-=0.9')
          timeline.to(parRight, 1, {opacity: 1, ease: Power3.easeOut}, '-=1')
          timeline.fromTo(char_smallDesc, {duration: 0.1, opacity:0, y:"150%"}, { duration: 0.7, opacity:1,  y:0, stagger: 0.03, ease: Power4.easeOut}, '-=1.1')
          timeline.to(circle, 3, {strokeDashoffset: 0, ease: Power4.easeOut}, "-=0.5")

      }
    }
</script>

<style lang="scss">
.heroscreen {
  width: 100%;
  height: 100vh;
  position: relative;
}
.heroscreen_content {
  display: flex;
  align-items: center;

  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  .bigTitle {
    position: relative;
    width: 90%;
    margin: auto;
    box-sizing: border-box;
    z-index: 300;

    .hidding_name {
      width: 90%;
      overflow: hidden;
      padding-top: 45px;
    }

    .hidding_desc, .hidding_smallDesc {
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
    }

    .hidding_desc {
        line-height: 1;
        margin-top: 10px;
    }

    .hidding_smallDesc {
        transform: translateY(-10px);
        margin-left: 30px ;
    }

    .hidding_job {
        overflow: hidden;
        width: 100%;
        padding-top: 15px;
        line-height: 0.8;
    }

    &_name {
      width: 100%;
      color: #fff;
      font-family: "Blake";
      font-size: 8.75rem;
      text-align: right;
      text-transform: uppercase;
      line-height: 0.8;
      // transform: translateY(200%);
    }

    &_job {
      color: #fff;
      font-family: "Blake";
      font-size: 8.75rem;
      text-align: right;
      text-transform: uppercase;
      line-height: 0.8;
      // transform: translateY(150%);
    }

    &_desc {
      color: #fff;
      font-family: "Blake";
      font-size: 8.75rem;
      text-transform: uppercase;
      // transform: translateY(100%);
    }

    &_smallDesc {
        color: #fff;

      span {
        font-size: 5.5rem;
        display: inline-block;
        vertical-align: middle;
        font-family: "blake";
        // transform: translateY(-20px);
        margin: -15px 5px 0 5px;
        opacity: 0;
      }

      &_info {
        width: 150px;
        font-size: 1rem;
        font-family: "Telegraf-UltraLight";
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
      }
      
    }
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;

    svg {
      stroke-dasharray: 3500px;
      stroke-dashoffset: 3500;
    }
  }
}
@media (max-width: 1500px) {
  .bigTitle{
    width: 85% !important;
  }

  .bigTitle_name,
  .bigTitle_job,
  .bigTitle_desc {
    font-size: 6em !important; 
  }
}

@media (max-width: 1281px) {
  .bigTitle_name,
  .bigTitle_job,
  .bigTitle_desc {
    font-size: 5.75em !important; 
  }
  .canvas {
    display: none !important;
  }
}

@media (max-width: 1025px) {
  .bigTitle_name,
  .bigTitle_job,
  .bigTitle_desc {
    font-size: 4.75em !important; 
  }

  .circle {
    display: none;
  }
}

@media (max-width: 770px) {
  .bigTitle_name,
  .bigTitle_job,
  .bigTitle_desc {
    font-size: 3.5em !important; 
  }
}

@media (max-width: 420px) {
  .bigTitle_name,
  .bigTitle_job,
  .bigTitle_desc {
    font-size: 2.25em !important; 
  }

  .bigTitle_smallDesc_info {
    font-size: 0.65em !important;
    width: 100px !important;
  }

  .bigTitle_smallDesc {
    span {
      font-size: 2em !important;
    }
  }

  .hidding_smallDesc {
    transform: translateY(10px) !important;
    margin: unset !important;
  }
}


</style>
