<template>
  <div class="section-project" id="projects">
    <div class="section-project__container">
      <div class="section-project__item">
        <div class="section-project__content">
          <img
            src="../assets/images/interface/DATARANTINO_POCHETARD.png"
            alt=""
            class="section-project__image"
          />
          <p class="section-project__title">Datarantino</p>
          <div class="section-project__description">
            <p class="section-project__leftP">(</p>
            <p class="section-project__descContent">
              Web experience and datavizualisation about Quentin Tarantino's
              movies
            </p>
            <p class="section-project__rightP">)</p>
          </div>
          <div class="section-project__links">
            <a
              href="https://github.com/leoB4/datarantino"
              class="section-project__cta"
              >Github
              <div class="section-project__circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1531.632"
                  height="713.728"
                  viewBox="0 0 1531.632 713.728"
                >
                  <g
                    id="Ellipse_home"
                    data-name="Ellipse home"
                    transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="30"
                    opacity="0.7"
                  >
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740.5"
                      ry="281"
                      stroke="none"
                    />
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740"
                      ry="280.5"
                      fill="none"
                    />
                  </g>
                </svg></div
            ></a>
            <a
              href="https://datarantino.netlify.app/"
              class="section-project__cta"
              >Project
              <div class="section-project__circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1531.632"
                  height="713.728"
                  viewBox="0 0 1531.632 713.728"
                >
                  <g
                    id="Ellipse_home"
                    data-name="Ellipse home"
                    transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="30"
                    opacity="0.7"
                  >
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740.5"
                      ry="281"
                      stroke="none"
                    />
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740"
                      ry="280.5"
                      fill="none"
                    />
                  </g>
                </svg></div
            ></a>
          </div>
        </div>
      </div>
      <div class="section-project__item">
        <div class="section-project__content">
          <img
            src="../assets/images/interface/MIRAGE_POCHETARD.png"
            alt=""
            class="section-project__image"
          />
          <p class="section-project__title">Mirage</p>
          <div class="section-project__description">
            <p class="section-project__leftP">(</p>
            <p class="section-project__descContent">
              WebGL experience about Lyon's art, innovation et culture festival,
              called Mirage.
            </p>
            <p class="section-project__rightP">)</p>
          </div>
          <div class="section-project__links">
            <a
              href="https://github.com/leoB4/mirage"
              class="section-project__cta"
              >Github
              <div class="section-project__circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1531.632"
                  height="713.728"
                  viewBox="0 0 1531.632 713.728"
                >
                  <g
                    id="Ellipse_home"
                    data-name="Ellipse home"
                    transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="30"
                    opacity="0.7"
                  >
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740.5"
                      ry="281"
                      stroke="none"
                    />
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740"
                      ry="280.5"
                      fill="none"
                    />
                  </g>
                </svg></div
            ></a>
            <a
              href="https://mirage-scbc.netlify.app/"
              class="section-project__cta"
              >Project
              <div class="section-project__circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1531.632"
                  height="713.728"
                  viewBox="0 0 1531.632 713.728"
                >
                  <g
                    id="Ellipse_home"
                    data-name="Ellipse home"
                    transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="30"
                    opacity="0.7"
                  >
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740.5"
                      ry="281"
                      stroke="none"
                    />
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740"
                      ry="280.5"
                      fill="none"
                    />
                  </g>
                </svg></div
            ></a>
          </div>
        </div>
      </div>
      <div class="section-project__item">
        <div class="section-project__content">
          <img
            src="../assets/images/interface/MYLAB_POCHETARD.png"
            alt=""
            class="section-project__image"
          />
          <p class="section-project__title">My Lab</p>
          <div class="section-project__description">
            <p class="section-project__leftP">(</p>
            <p class="section-project__descContent">
              All of my experiences about code, web development and other shit.
            </p>
            <p class="section-project__rightP">)</p>
          </div>
          <div class="section-project__links">
            <a href="https://github.com/leoB4/old_LAB" class="section-project__cta"
              >Github
              <div class="section-project__circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1531.632"
                  height="713.728"
                  viewBox="0 0 1531.632 713.728"
                >
                  <g
                    id="Ellipse_home"
                    data-name="Ellipse home"
                    transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="30"
                    opacity="0.7"
                  >
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740.5"
                      ry="281"
                      stroke="none"
                    />
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740"
                      ry="280.5"
                      fill="none"
                    />
                  </g>
                </svg>
              </div>
            </a>
            <a href="http://leoblondin.com/old/Lab/index.html" class="section-project__cta"
              >Project
              <div class="section-project__circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1531.632"
                  height="713.728"
                  viewBox="0 0 1531.632 713.728"
                >
                  <g
                    id="Ellipse_home"
                    data-name="Ellipse home"
                    transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 154.807)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="30"
                    opacity="0.7"
                  >
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740.5"
                      ry="281"
                      stroke="none"
                    />
                    <ellipse
                      cx="740.5"
                      cy="281"
                      rx="740"
                      ry="280.5"
                      fill="none"
                    />
                  </g>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
};
</script>

<style lang="scss">
.section-project {
  z-index: 300;
  position: relative;

  &__container {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-around;
    width: 100%;
  }

  &__item {
    width: calc((100% / 3)- 15px);
    margin: 50px 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &__image {
    width: 350px;
    height: auto;
  }

  &__title {
    font-family: "Blake", "serif";
    color: #fff;
    font-size: 3em;
    text-align: center;
    margin: 30px 0;
  }

  &__description {
      margin-bottom: 20px;
  }

  &__leftP,
  &__rightP,
  &__descContent {
    color: #fff;
    display: inline-block;
    vertical-align: middle;
  }

  &__leftP,
  &__rightP {
    font-size: 5.5em;
    font-family: "Blake";
    margin: 0;
  }

  &__descContent {
    max-width: 215px;
    text-align: center;
    font-family: "Telegraf-UltraLight";
    line-height: 1.5;
    font-size: 1em;
  }

  &__links {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  &__cta {
    color: #fff;
    font-size: 1em;
    font-family: "Telegraf-UltraLight";
    text-decoration: none;
    position: relative;
    box-sizing: border-box;
    text-transform: uppercase;

    &:hover {
        .section-project__circle {
            svg {
                stroke-dashoffset: 0;
            }
        }
    }
  }

    &__circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        transform: translate(-45px, -13px);

        svg {
            width: 120%;
            height: 120%;
            stroke-dasharray: 3500;
            stroke-dashoffset: 3500;
            transform: rotate(-10deg);
            transition: 0.5s ease-out;
        }
    }
}

 @media (max-width: 1025px) {
   .section-project__item {
     width: calc((100% / 2) - 30px)  !important;
   }
 }

 @media (max-width: 770px) {
   .section-project__item {
     width: 100%  !important;
   }
 }

  @media (max-width: 420px) {
    .section-project__image {
      width: 250px !important;
    }

    .section-project__title {
      font-size: 2em !important;
      margin: 20px 0 10px 0 !important;
    }
  }
</style>
