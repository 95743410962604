<template>
  <footer>
      <div class="footer_wrapper">
          <div class="footer_wrapper_infos">
              <p class="infos_title uppercase"><strong>Léo Blondin</strong></p>
              <p class="infos_content">All rights reserved - 2021 © Léo Blondin</p>
          </div>
          <div class="footer_wrapper_infos">
              <p class="infos_title">Contact</p>
              <a href="mailto:hello@leoblondin.com" class="infos_content">hello@leoblondin.com</a>
          </div>
          <div class="footer_wrapper_infos">
              <p class="infos_title">Follow me</p>
              <div class="infos_content links">
                  <a href="https://twitter.com/leo_blondin" target="_blank"><img src="../assets/images/interface/twitter.png" alt="Twitter leo blondin"></a>
                  <a href="https://github.com/leoB4" target="_blank"><img src="../assets/images/interface/github.png" alt="Github leo blondin"></a>
                  <a href="https://www.linkedin.com/in/l%C3%A9o-blondin-418b03154/" target="_blank"><img src="../assets/images/interface/linkedin.png" alt="LinkedIn leo blondin"></a>
              </div>
          </div>
          <div class="footer_wrapper_infos">
              <p class="infos_title">Designed by</p>
              <a href="http://www.bastienmaillotte.fr/" class="infos_content" target="_blank">Bastien Maillotte</a>
          </div>
      </div>
  </footer>
</template>

<script>
    export default {
    name: "Footer",
    };
</script>

<style lang="scss">

    footer {
        width: 100%;
        padding: 35px 0;
        z-index: 300;
        margin-top: 60px;

        .uppercase {
            text-transform: uppercase;
        }

        .footer_wrapper {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            width: 90%;
            margin: 32px auto;
            z-index: 500;

            &_infos {
                width: calc(100%/4 - 25px);

                .infos_title {
                    font-family: "Blake";
                    font-size: 1.5em;
                    font-weight: normal;
                    color: #FFF;
                    margin: 0;
                }

                .infos_content {
                    font-family: "Telegraf-UltraLight";
                    font-size: 1em;
                    font-weight: normal;
                    color: #FFF;
                    margin-top: 20px;
                    display: block;
                }

                .links {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: space-between;
                    width: fit-content;

                    a {
                        margin-right: 23px;
                    }
                }
            }
        }
    }

    @media (max-width: 770px) {
        .footer_wrapper_infos {
            width: calc(100%/2 - 25px) !important; 
            margin: 30px 0;
        }
    }

    @media (max-width: 420px) {
        .footer_wrapper_infos {
            width: calc(100% - 25px) !important; 
            margin: 15px 0;
        }
    }

</style>
