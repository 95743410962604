<template>
  <vue-p5 @sketch="sketch" v-on="this" class="canvas"></vue-p5>
</template>

<script>
    import VueP5 from 'vue-p5';
    import Leo from '../assets/images/stickers/STICKER_LEO.png'
    import Mirrorball from '../assets/images/stickers/STICKER_MIRRORBALL.png'
    import Planet from '../assets/images/stickers/STICKER_PLANET.png'
    import Techno from '../assets/images/stickers/STICKER_TECHNO.png'
    import Yinyang from '../assets/images/stickers/STICKER_YINYANG.png'

    export default {

        name: "Particles",

        components: {
            VueP5
        },

        methods: {
            sketch(sk) {
                let images = []
                let particles = []
                let cooldown = 10
                let timer = 0
                let w = window.innerWidth * window.devicePixelRatio
                let h = window.innerHeight * window.devicePixelRatio

                sk.preload = () => {
                    images[0] = sk.loadImage(Leo);
                    images[1] = sk.loadImage(Mirrorball);
                    images[2] = sk.loadImage(Planet);
                    images[3] = sk.loadImage(Techno);
                    images[4] = sk.loadImage(Yinyang);
                }
        
                sk.setup = () => {
                    sk.createCanvas(w, h);
                }

                sk.draw = () => {
                    sk.innerWidth = w
                    sk.innerHeight = h
                    sk.background(0);;
                    particles.forEach((p) => {
                        p.display()
                        p.live()
                    });
                }

                 sk.mouseMoved = () => {
                    timer --
                    if (timer < 0) {
                        let p = new Particle()
                        particles.push(p)
                        timer = cooldown
                    }
                }

                class Particle {
                    constructor() {
                        this.image = {...images[sk.floor(sk.random(images.length))]}
                        this.x = sk.mouseX
                        this.y = sk.mouseY
                        this.lifespan = 150
                    }


                    display() {
                        sk.image(this.image, this.x-this.lifespan/2, this.y-this.lifespan/2, this.lifespan, this.lifespan)
                    }

                    live() {
                        this.lifespan-=3
                        if (this.lifespan <= 0)
                        particles.splice(particles.indexOf(this), 1)
                    }
                }
                
            }
        },

        render(h) {
            return h(VueP5, {on: this});
        }
    }
</script>
<style lang="scss">
    .canvas {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 100;

    }
</style>